import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navigation from "../components/navigation"
import Love from "../components/icons/love"
import Honesty from "../components/icons/honesty"
import Respect from "../components/icons/respect"
import Communication from "../components/icons/communication"
import styled from "styled-components"
import Tag from "../components/tag"

const JobPosition = styled.h2`
  font-size: 12px;
  margin-top: -10px;
`
const About = ({ data, location }) => {
  const { siteTitle, social, author } = data.site.siteMetadata
  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="About" />
      <Navigation />
      <h1> Marko Bianchi </h1>
      <JobPosition>
        {author.job.position} @{" "}
        <a
          rel="noopener noreferrer"
          target="_BLANK"
          href={author.job.company.url}
        >
          {author.job.company.title}
        </a>
      </JobPosition>
      <p>
        My interests range from software development to cycling. I am also
        interested in privacy, technology, philosophy, photography and
        psychology.
      </p>
      <p>
        You can find me taking photos and, on the weekends, and afternoons
        riding my road cycle. I am looking for photography collaborators.
      </p>
      <p>
        I have been working professionally on media related CMS for the past 6
        years. My specialization is media and CMS, alongside DevOps. I have
        worked in Adult entertainment industry, YouTube/Creator industry and web
        first/TV industry. I can offer great insight into these markets and CMS
        used for distributing, managing and augmenting media.
      </p>
      <h3> Some of the text is still work in progress! </h3>
      <p>
        Help me improve any typos or mistakes
        <Tag
          tag={"marko@bianchi.hr"}
          link={"mailto:marko@bianchi.hr"}
          external={true}
        />
      </p>
      <h2> My values </h2>
      <p>
        There are four values that are most important to me and those are
        Honesty, Respect, Love, Good Communication (<strong>HRLC</strong>). I
        provide and expect these values in return. We can all be better humans
        if we are honest, respectful, full of love and are able to communicate
        it. Afterall all of these things are what makes us human.
      </p>
      <h3> Honesty </h3>
      <Honesty />
      <p>
        I value honesty above all. If people were to be more honest and less
        deciving and manipulative for personal gain we as a humanity would go
        long way. You can except absolute honesty from me and I exact the same
        from anybody around me. Being honest can be hurtful but there is a fine
        line between being an asshole and honest human, I try to stay above the
        line.
      </p>
      <h3> Respect </h3>
      <Respect />
      <p>
        No matter who you are, no matter what you are everyone deserves respect.
        Either my worst enemy, someone who doesn't agree to my values or a
        beggar on the streets. Respect is what I give as a unlimited resource
        and I expect to receive it.
      </p>
      <h3> Love (Emphaty) </h3>
      <Love />
      <p>
        Love is an amazingly beautiful emotion that makes everyone, and
        everything flourish. Imagine if we all truly loved each other as we love
        our phones, our social media or chocolate. Show some emotion don't drown
        yourself in toxic masculinity where a man should not show emotions or
        love others. Love is something we are possess and more we give it more
        it comes back to us. Expect love from me, lots of love.
      </p>
      <h3> Good Communication </h3>
      <Communication />
      <p>
        Communication has always been the most valuable of human traits and it's
        an art. How do you communicate to someone you are honest about
        something, or you love them or have respect towards them. This is a tie
        in value. It's a special kind of value as it works like a glue that
        helps to use, share, express other values. It's as important if not
        more. Without clear communication we are just empty shells, living our
        own perspective in a lonely world.
      </p>
      <h3> Future and Goals </h3>
      <p>
        My current goal is to move away from full stack development into backend
        and devops, while focusing on development of soft and managerial skills.
        I want to help people I work with and people I will work with to start
        talking about their values and help them integrate those values with
        good communication.
      </p>
      <p>
        Currently I am trying to learn every possible aspect of business and
        development. Idea is to understand people, common pitfalls and patterns
        with modern development. I stand behind the idea of "
        <i>Jack of All Trades, Master of None</i>".
      </p>
      <p>
        I strongly believe having more experiences in different, unrelated
        aspects of business and life can have a profound difference in creating
        something special. Being in full stack development has helped me
        understand problems and issues frontend developers have to deal with
        every day and is making me a better backend developer.
      </p>
      <p>
        Idea for future is to use all the experience and knowledge gained to
        help build a team and helps others to do the same. I want to explore
        backend development, human relations and product development as part of
        my life goal. Combining my values, good communication and my endless
        curiosity, hopefully start helping others find the edge, fix common
        problems or overall be better humans.
      </p>
      <p>
        Farewell, fellow human, if you feel like our values align and we can do
        business of any kind please do not hesitate to contact me
        <Tag
          tag={"marko@bianchi.hr"}
          link={"mailto:marko@bianchi.hr"}
          external={true}
        />
      </p>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author {
          job {
            show
            position
            company {
              title
              url
            }
          }
        }
        title
        social {
          title
          url
        }
      }
    }
  }
`
