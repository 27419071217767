import React from "react"
import styled from "styled-components"

const SvgContainer = styled.div`
  svg {
    width: 100%;
    height: 200px;
  }
`
function Honesty() {
  return (
    <SvgContainer>
      <svg
        id="a8897f4c-2937-4134-8b3a-fee1040aeff6"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="1066.10578"
        height="824.1997"
        viewBox="0 0 1066.10578 824.1997"
      >
        <title>people</title>
        <polygon
          points="962.551 394.71 946.672 466.13 933.782 524.07 923.508 570.28 910.626 628.22 868.531 817.51 346.914 576.48 308.572 558.76 260.039 536.33 221.683 518.61 175.551 497.29 201.704 459.24 223.452 427.6 250.965 387.57 272.707 355.95 517.369 0 776.55 229.8 822.703 270.72 859.505 303.35 905.666 344.27 962.551 394.71"
          fill="#f2f2f2"
        />
        <path
          d="M271.30076,642.73135C236.42088,623.287,214.707,596.74014,206.7617,563.829c-5.89942-24.43555-4.2041-51.93262,5.03808-81.72754a218.88823,218.88823,0,0,1,30.439-61.48437c70.63818-99.98633,77.01367-165.1377,69.92334-202.18653-7.77393-40.623-33.03125-57.49414-33.28565-57.65967l1.09034-1.67675c.26074.16943,26.18359,17.43261,34.14453,58.87988,4.58935,23.89453,2.43017,51.14062-6.41748,80.981-11.01172,37.13769-32.48438,78.459-63.82129,122.8164a216.90408,216.90408,0,0,0-30.1626,60.92188c-15.1582,48.86718-17.30273,115.99707,58.56445,158.292Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <path
          d="M288.07412,642.73135C322.954,623.287,344.66787,596.74014,352.61318,563.829c5.89942-24.43555,4.2041-51.93262-5.03808-81.72754a218.88823,218.88823,0,0,0-30.439-61.48437c-70.63818-99.98633-77.01367-165.1377-69.92334-202.18653,7.77393-40.623,33.03125-57.49414,33.28565-57.65967l-1.09034-1.67675c-.26074.16943-26.18359,17.43261-34.14453,58.87988-4.58935,23.89453-2.43017,51.14062,6.41748,80.981,11.01172,37.13769,32.48438,78.459,63.82129,122.8164a216.90408,216.90408,0,0,1,30.1626,60.92188c15.1582,48.86718,17.30273,115.99707-58.56445,158.292Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <path
          d="M1048.30076,558.73135C1013.42088,539.287,991.707,512.74014,983.7617,479.829c-5.89942-24.43555-4.2041-51.93262,5.03808-81.72754a218.88823,218.88823,0,0,1,30.439-61.48437c70.63818-99.98633,77.01367-165.1377,69.92334-202.18653-7.77393-40.623-33.03125-57.49414-33.28565-57.65967l1.09034-1.67675c.26074.16943,26.18359,17.43261,34.14453,58.87988,4.58935,23.89453,2.43017,51.14062-6.41748,80.981-11.01172,37.13769-32.48438,78.459-63.82129,122.8164a216.90408,216.90408,0,0,0-30.1626,60.92188c-15.1582,48.86718-17.30273,115.99707,58.56445,158.292Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <path
          d="M1065.07412,558.73135c34.87988-19.44433,56.59375-45.99121,64.53906-78.90234,5.89942-24.43555,4.2041-51.93262-5.03808-81.72754a218.88823,218.88823,0,0,0-30.439-61.48437c-70.63818-99.98633-77.01367-165.1377-69.92334-202.18653,7.77393-40.623,33.03125-57.49414,33.28565-57.65967l-1.09034-1.67675c-.26074.16943-26.18359,17.43261-34.14453,58.87988-4.58935,23.89453-2.43017,51.14062,6.41748,80.981,11.01172,37.13769,32.48438,78.459,63.82129,122.8164a216.90408,216.90408,0,0,1,30.1626,60.92188c15.1582,48.86718,17.30273,115.99707-58.56445,158.292Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <path
          d="M534.762,337.84484c-26.20687-44.2815-78.05507-46.34521-78.05507-46.34521s-50.52312-6.46085-82.93338,60.98042c-30.209,62.86081-71.90115,123.55421-6.71211,138.26947l11.775-36.64918,7.29222,39.37767a255.06843,255.06843,0,0,0,27.892.47669c69.81221-2.25395,136.29778.65944,134.157-24.39211C545.332,436.26009,559.97826,380.4525,534.762,337.84484Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <path
          d="M427.19425,430.52233c9.03031,19.97526,14.72473,43.78567,18.12479,70.23353l72.49913-4.5312c-16.66786-23.6189-22.20214-50.72822-15.85919-81.56152Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#ffb8b8"
        />
        <path
          d="M427.19425,430.52233c9.03031,19.97526,14.72473,43.78567,18.12479,70.23353l72.49913-4.5312c-16.66786-23.6189-22.20214-50.72822-15.85919-81.56152Z"
          transform="translate(-66.94711 -37.90015)"
          opacity="0.2"
        />
        <path
          d="M90.81926,810.84366s-37.5228,20.09888-18.48195,37.25877,44.19287-22.71411,44.19287-22.71411Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#ffb8b8"
        />
        <polygon
          points="482.591 714.334 482.005 741.792 480.754 801.122 480.754 801.132 480.39 818.314 240.438 818.314 250.19 764.163 250.2 764.123 258.932 715.656 262.829 693.951 262.829 693.941 459.937 666.755 482.591 714.334"
          fill="#2f2e41"
        />
        <path
          d="M549.53654,752.23722l-.58908,27.459c-69.73517-1.78991-177.82675-18.62329-223.07067-26.14509l3.89676-21.70432,197.107-27.18718Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#3f3d56"
        />
        <circle cx="395.36391" cy="355.23981" r="56.63995" fill="#ffb8b8" />
        <path
          d="M571.05972,782.82279s6.79679,61.17114-33.984,56.63994l-6.7968-61.17114L494.02939,742.042l-101.9519-2.2656L317.31276,810.01s-2.2656-72.49913,6.79679-79.29592,29.45278-22.656,29.45278-22.656v-11.328L292.39118,572.12219s36.24957-74.76473,65.70234-77.03033l38.51516-2.26559s13.59359-6.7968,13.59359-9.0624-2.2656-20.39038,9.06239-15.85918c0,0,1.01941-23.24506,12.95916-16.15372,0,0,37.58646,29.45277,75.73905,1.2914,0,0,17.78489-3.26246,15.51929,5.79993s0,15.85918,4.5312,15.85918,24.92157,6.7968,24.92157,11.328c0,.97419,1.4727,10.3538,3.78337,24.46843v.02268c8.40556,51.45164,27.935,165.81911,27.935,165.81911S605.04368,705.79246,571.05972,782.82279Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#1abc9c"
        />
        <path
          d="M308.25037,560.7942l-15.85919,11.328-144.99826,185.779L70.3626,819.07235s43.04636-6.79679,33.984,29.45277L369.42151,696.73007Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#1abc9c"
        />
        <path
          d="M556.7183,510.4979v.02268c-3.01286,17.717-101.20407,14.02406-114.79766,14.02406s-24.92158-2.2656-38.51516-11.328-6.7968-20.39038-6.7968-20.39038,11.57456-8.81583,11.57456-11.08143-2.2656-20.39038,9.06239-15.85919c0,0,1.01941-23.245,12.95915-16.15372,0,0,39.6055,31.47181,77.75809,3.31044,0,0,17.78489-3.26246,15.51929,5.79993s0,15.85918,4.5312,15.85918,24.92157,6.7968,24.92157,11.328C552.93493,487.00366,554.40763,496.38327,556.7183,510.4979Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#3f3d56"
        />
        <path
          d="M545.00534,425.99113s-18.12478-38.51516-36.24956-20.39038,20.39038,45.312,20.39038,45.312Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#ffb8b8"
        />
        <path
          d="M545.00534,416.92874s-27.98762,35.36953-36.64979,38.07515c0,0,25.3218,100.12632,57.04017,127.31349l18.12478,92.88951s77.03033-31.71837,58.90555-83.82712q-.27187-.78163-.541-1.55376a513.98548,513.98548,0,0,0-62.09845-122.40839Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#1abc9c"
        />
        <polygon
          points="463.102 483.933 518.839 637.307 498.449 544.417 463.102 483.933"
          opacity="0.2"
        />
        <polygon
          points="456.229 295.676 405.005 268.845 334.268 279.821 319.632 344.46 356.064 343.059 366.242 319.311 366.242 342.668 383.052 342.021 392.809 304.213 398.907 344.46 458.668 343.241 456.229 295.676"
          fill="#2f2e41"
        />
        <path
          d="M1007.07589,682.23771,1036.13813,785.909s-5.98872,91.827,21.95862,73.86082c28.59982-18.3856,17.96614-95.81944,17.96614-95.81944L1059.311,670.94362Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#a0616a"
        />
        <path
          d="M1022.60526,391.41495l23.99994,23.99994V529.76753s14.11761,21.17641,11.29408,26.82345-5.647,5.64705-2.82352,9.88233,5.64705,7.0588,2.82352,9.88233-2.82352,9.88232,0,12.70584,8.47057,7.05881,4.23529,11.29409-9.88233,1.41176-4.23529,16.94113,15.52937,19.76466,12.70585,25.4117-2.82352,19.76465-2.82352,19.76465l-60.70572,8.47057L994.37,511.41463Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#d0cde1"
        />
        <polygon
          points="1003.658 623.161 933.07 625.985 935.893 655.632 998.011 644.338 1003.658 623.161"
          fill="#3f3d56"
        />
        <polygon
          points="809.541 205.986 801.07 290.691 887.188 303.397 893.541 254.692 899.894 205.986 809.541 205.986"
          fill="#a0616a"
        />
        <polygon
          points="809.541 205.527 801.07 290.233 887.188 302.939 893.541 254.233 899.894 205.527 809.541 205.527"
          opacity="0.1"
        />
        <path
          d="M689.42968,646.94368l47.99987,103.05855s8.47057,47.99987,21.17642,49.41163,25.41169-49.41163,25.41169-49.41163l-16.94113-26.82346-19.76465-76.23509Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#a0616a"
        />
        <path
          d="M1018.00305,857.214H746.6346c2.15028-34.01065,3.503-54.97836,3.503-54.97836l3.604-5.67348,17.47475-27.45889L783.694,749.49843l4.351-6.84456,1.61527-2.53384,17.09113-3.11943L983.0737,704.82727a215.42643,215.42643,0,0,0,12.32623,38.1093c1.20133,2.73581,2.49348,5.47156,3.87652,8.17706,4.43179,8.65158,9.84279,16.84886,16.27348,22.88581,1.46375,1.37293,2.52379,5.03749,3.21026,10.67061C1020.45617,798.47014,1019.97156,824.13207,1018.00305,857.214Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <circle cx="856.83489" cy="165.7506" r="67.76452" fill="#a0616a" />
        <path
          d="M772.72358,342.00332l-9.88233-5.647s-43.76459,29.647-57.8822,95.99974-23.99993,83.2939-23.99993,83.2939-14.11761,2.82352-4.23529,12.70584,9.88233,8.47057,1.41176,14.11761-14.11761-5.647-8.47056,5.647,12.70585,8.47056,7.0588,15.52937-15.52937,7.0588-9.88232,12.70585-5.647,18.35289,1.41176,23.99993,9.88232,7.05881,9.88232,16.94113a49.9927,49.9927,0,0,0,2.82353,16.94113h73.41156s-14.11761-26.82345-9.88232-32.4705,5.647-11.29408,2.82352-14.11761-11.29409,2.82353-2.82352-4.23528,11.29408-2.82352,8.47056-11.29409-8.47056-4.23528-2.82352-8.47056,9.88233-2.82352,7.05881-7.05881-8.47057-4.23528-2.82353-7.0588,9.88233,0,8.47057-7.05881-2.82352-4.23528,0-7.0588,32.4705-98.82327,32.4705-101.64679S772.72358,342.00332,772.72358,342.00332Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#d0cde1"
        />
        <polygon
          points="691.659 590.691 602.718 596.338 625.306 617.514 687.424 618.926 691.659 590.691"
          fill="#3f3d56"
        />
        <path
          d="M784.01766,734.47286l-18.35289,33.88226s55.05868,7.05881,74.82333,14.11761,70.588,16.94113,70.588,16.94113L923.782,750.00223Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#3f3d56"
        />
        <polygon
          points="878.011 712.102 893.541 758.69 955.658 745.984 948.599 702.22 878.011 712.102"
          fill="#3f3d56"
        />
        <path
          d="M970.37011,294.00345s-4.23529-2.82352-14.11761,2.82352-42.35283,8.47057-57.8822-8.47056-26.82346-31.05874-31.05874-28.23522-7.05881,35.294-7.05881,35.294L846.13514,306.7093l-83.29389,29.647,7.0588,190.58772,12.70585,43.76459s-9.88232,33.88227,2.82352,53.64692-7.0588,26.82346-7.0588,26.82346-4.23528,5.647-1.41176,19.76465-2.82352,66.35276-2.82352,66.35276,119.99968,53.64692,245.6464,7.05881l-14.11761-53.64692-7.05881-119.99968s7.05881-19.76465,5.64705-28.23522,42.35283-127.05848,42.35283-127.05848-7.05881-59.294-43.76459-71.99981-42.35283-25.41169-42.35283-25.41169S977.42891,299.65049,970.37011,294.00345Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#d0cde1"
        />
        <path
          d="M855.54758,212.33147s-7.037-10.55549-4.33045-21.92294a66.44367,66.44367,0,0,0,1.89457-19.7577s5.68372-18.40444,11.36745-28.68928,2.97719-14.61529,20.84032-17.86313,10.55549-26.524,57.6492-1.35327a13.81105,13.81105,0,0,0,14.34464,5.41307c9.74353-1.89457,13.262,11.6381,13.262,11.6381s6.49569-3.78914,8.93157,1.62393,19.8043-3.24882,19.8043,33.2894-19.76465,69.17629-19.76465,69.17629,1.44894-61.46168-30.75883-67.68672-66.85142-24.35881-75.24168-1.08261S855.54758,212.33147,855.54758,212.33147Z"
          transform="translate(-66.94711 -37.90015)"
          fill="#2f2e41"
        />
        <ellipse
          cx="913.30532"
          cy="180.57409"
          rx="6.35292"
          ry="8.47057"
          fill="#a0616a"
        />
      </svg>
    </SvgContainer>
  )
}

export default Honesty
