import React from "react"
import styled from "styled-components"

const SvgContainer = styled.div`
  svg {
    width: 100%;
    height: 200px;
  }
`
function Respect() {
  return (
    <SvgContainer>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1136"
        height="691.423"
        data-name="Layer 1"
        viewBox="0 0 1136 691.423"
      >
        <path
          fill="#ffb8b8"
          d="M823.78 435.78s-45.698-9.256-39.913 10.411 43.962 6.942 43.962 6.942z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#575a89"
          d="M922.368 331.895a4.287 4.287 0 017.111 2.45l1.562 9.072s7.061 36.286 1.015 59.336-10.336 53.412-26.674 56.646c-13.981 2.768-67.14-1.615-82.188-2.937a4.283 4.283 0 01-3.907-4.364l.281-13.24a4.29 4.29 0 013.834-4.175l60.862-6.491a4.281 4.281 0 003.793-3.664c1.447-10.355 6.129-42.456 10.779-59.334 3.757-13.638 16.098-26.515 23.532-33.299z"
          transform="translate(-32 -104.288)"
        ></path>
        <path fill="#3f3d56" d="M716 668.253H1136V670.253H716z"></path>
        <path
          fill="#ffb8b8"
          d="M850.822 614.608L850.822 642.374 866.44 645.844 876.853 640.638 875.696 618.657 850.822 614.608z"
        ></path>
        <path
          fill="#ffb8b8"
          d="M943.086 614.608L943.086 642.374 958.705 645.844 969.117 640.638 967.96 618.657 943.086 614.608z"
        ></path>
        <path
          fill="#2f2e41"
          d="M914.059 493.297s-20.825 42.228-20.246 75.2a452.259 452.259 0 01-2.892 59.581l-10.413 98.917 33.55 1.735 15.62-106.437 30.08-82.14 11.568 97.759 2.893 98.338h28.923s13.304-93.132 5.784-113.956c0 0 17.354-116.85 3.47-122.634s-98.337-6.363-98.337-6.363z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#2f2e41"
          d="M893.234 739.142s-5.784-21.403-13.883-10.412-31.236 29.501-31.236 29.501-34.708 18.511-2.893 23.139 35.286-5.785 35.286-5.785 5.206-2.892 9.834-1.157 24.874 1.157 26.03-5.784-6.362-45.12-8.676-38.179-14.462 8.677-14.462 8.677zM999.67 738.564s0-6.363-10.99-6.942-12.726 6.942-12.726 6.942-2.892 2.892-3.47 11.569 0 31.237 0 31.237-5.207 17.353 18.51 13.883 15.04-21.403 15.04-21.403z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#ffb8b8"
          d="M957.443 277.532l-2.892 32.394-5.206 12.147 13.883 19.668 34.129-31.815s-6.363-17.354-2.314-27.188z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#575a89"
          d="M1034.957 333.064l-.266 2.852-10.147 108.212s-.399 5.033-1.139 11.743c-1.25 11.35-3.47 27.494-6.38 32.22-4.628 7.52-1.157 13.883-1.157 13.883-26.61 15.618-105.28-6.941-105.28-6.941l17.932-71.73s-16.196-74.62-14.461-81.562 38.178-31.815 38.178-31.815l1.076 2.892c15.532 20.825 31.318 2.892 35.946-1.157l9.255-8.098z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#3f3d56"
          d="M916 531.541c-55.15 7.482-107.762 10.22-156.445 5.614a377.44 377.44 0 01-51.316-8.812c-30.955-7.327-68.953-7.327-110.7-3.426a317.74 317.74 0 00-74.514 15.804c-32.352 11.199-67.793 10.229-105.368.854a192.172 192.172 0 01-44.242-17.616c-27.47-14.762-59.982-9.417-95.415 7.582v-132c33.08-14.407 65.307-16.732 96.51-4.587a203.878 203.878 0 0035.14 10.483 276.991 276.991 0 00130.553-5.896c57.369-19.438 117.379-13.396 178.151 0 67.707 14.3 133.697 15.144 197.646 0z"
          transform="translate(-32 -104.288)"
        ></path>
        <path fill="#3f3d56" d="M0 668.253H420V670.253H0z"></path>
        <path
          fill="#ff6584"
          d="M540.252 110.963H576.721V147.432H540.252z"
          transform="rotate(150 556.458 72.766)"
        ></path>
        <path
          fill="#3f3d56"
          d="M423.252 269.963H459.721V306.432H423.252z"
          transform="rotate(150 439.458 231.766)"
        ></path>
        <path
          fill="#d0cde1"
          d="M744.252 227.963H780.721V264.432H744.252z"
          transform="rotate(150 760.458 189.766)"
        ></path>
        <path
          fill="#2f2e41"
          d="M178.85 423.599L197.947 512.01 139.949 642.15 173.899 657.004 238.969 507.766 197.372 376.702 172.617 383.068 178.85 423.599z"
        ></path>
        <path
          fill="#2f2e41"
          d="M193.875 503.578s7.073 12.731 22.633 23.34 48.096 80.631 48.096 80.631L229.24 749.007l48.802 13.438 26.877-166.212-55.168-124.483zM176.193 741.488s-7.073 7.78-4.244 13.438 4.244 20.512 4.244 20.512h6.366v-16.975l20.51 19.097s47.39 4.243 46.682 0-28.292-14.146-28.292-14.146l-13.438-20.512z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#2f2e41"
          d="M238.434 743.349s-7.073 7.78-4.243 13.438 4.243 20.511 4.243 20.511h6.366v-16.974l20.511 19.096s47.388 4.244 46.681 0-28.291-14.145-28.291-14.145l-13.439-20.512z"
          transform="translate(-32 -104.288)"
        ></path>
        <circle cx="189.459" cy="178.616" r="26.877" fill="#a0616a"></circle>
        <path
          fill="#a0616a"
          d="M199.533 284.319s-2.121 26.877-15.56 36.779-13.438 18.39-13.438 18.39l54.46 24.755-12.73-26.877s-3.537-1.415 2.121-8.488 10.61-24.048 10.61-24.048z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#575a89"
          d="M255.056 474.933a152.336 152.336 0 01-50.22 29.151L192 508.541s-27.145-32.943-13.438-58.9a40.665 40.665 0 0111.777-13.962l-.283-1.238-6.882-30.025-.615-2.687-20.512-4.951s-.064.02-.184.05c-1.534.402-11.939 2.312-4.06-21.976 5.242-16.162 9.043-31.55 11.183-40.955a9.983 9.983 0 0112.345-7.44c6.568 1.801 14.727 5.221 19.617 11.616 9.195 12.024 11.052-.532 11.052-.532s25.727 28.823 24.312 41.555a50.6 50.6 0 00.312 10.241v.014c.17 1.514.374 3.091.608 4.704 1.754 11.91 5.149 25.752 6.394 30.618.092.347.17.65.233.905.149.588.234.906.234.906z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          d="M218.63 439.215l-5.658 19.097s-21.7-5.531-34.41-8.672a40.665 40.665 0 0111.777-13.961l-.283-1.238-6.882-30.025-.615-2.687-20.512-4.951s-.064.02-.184.05l-1.23-9.245s35.364 2.122 37.486 10.61-2.122 16.974-4.244 18.389 0 0 4.244 2.122-1.415 0-4.244 4.243.707.708 1.415 2.83 0-.708-.708 2.829 2.83.707 3.537.707 20.511 9.902 20.511 9.902z"
          opacity="0.2"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#a0616a"
          d="M212.265 435.679l45.973 12.023s24.755-25.462 38.194-17.682-24.048 25.463-24.048 25.463l-13.438 2.829-48.803-5.659z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#575a89"
          d="M160.633 384.754l2.829 21.218s-2.122 0 0 4.951 0 14.853 0 14.853-4.244.708-.708 2.83 7.073 7.78 5.659 9.902-4.244 4.95 2.122 6.365 42.437 10.61 42.437 10.61l5.658-19.097s-19.804-9.902-20.511-9.902-4.244 2.829-3.537-.708 1.415-.707.708-2.829-4.244 1.415-1.415-2.829 8.488-2.122 4.244-4.244-6.366-.707-4.244-2.121 6.366-9.902 4.244-18.39-37.486-10.61-37.486-10.61zM215.261 330.402l2.131-3.39a7.075 7.075 0 00-2.222-9.755l-16.239-10.213a7.075 7.075 0 00-9.755 2.222l-2.132 3.39a7.063 7.063 0 00-6.014 3.3l-5.244 8.34a7.075 7.075 0 002.222 9.755l21.883 13.762a7.075 7.075 0 009.755-2.222l5.244-8.339a7.063 7.063 0 00.371-6.85z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#2f2e41"
          d="M220.523 282.442c1.503-2.196.844-5.247 1.92-7.681 1.69-3.823 6.673-4.607 10.845-4.858a31.964 31.964 0 018.306.223 7.975 7.975 0 015.66 4.316c.585 1.25.524-2.623.25-7.601a24.023 24.023 0 00-17.898-21.932l-.174-.045c-5.04-1.292-10.246-2.279-15.427-1.797s-10.385 2.587-13.708 6.591c-1.519 1.83-2.614 4.005-4.3 5.682-2.964 2.946-7.275 3.94-11.245 5.243a33.534 33.534 0 00-12.353 6.734 18.176 18.176 0 00-6.191 12.344c-.217 5.508 3.15 11.28 8.772 12.503a6.343 6.343 0 012.68 1.234c5.292 4.312-.966 8.54-1.038 13.249-.06 3.929 4.252 6.595 8.17 6.873a15.814 15.814 0 0016.05-12.128c.67-3.21.293-6.531-.013-9.795-.35-3.736 7.885-12.315 2.43-6.013a9.224 9.224 0 00-2.13 4.217c-1.006 5.116 3.626 6.957 6.505 9.875a12.218 12.218 0 012.652 12.644 14.086 14.086 0 002.99-17.72c-.914-1.48-2.107-2.777-2.971-4.286-1.349-2.354-1.614-7.411 2.315-7.177 2.805.167 5.665 2.574 7.903-.695z"
          transform="translate(-32 -104.288)"
        ></path>
        <circle cx="945.111" cy="159.361" r="29.501" fill="#ffb8b8"></circle>
        <path
          d="M1033.534 337.073l-10.146 108.212s-.4 5.033-1.14 11.743c-21.536 5.148-81.002 9.082-81.002 9.082l-2.892-20.825 65.944-17.932s-8.098-45.12 1.157-63.63a143.111 143.111 0 006.073-13.84 20.079 20.079 0 0122.006-12.81z"
          opacity="0.2"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#ffb8b8"
          d="M944.717 442.393s-45.698-9.256-39.913 10.412 43.962 6.942 43.962 6.942z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#575a89"
          d="M1017.025 330.172l17.932 2.892s12.726 34.708 10.412 58.424-1.735 54.376-17.354 60.16-85.612 10.412-85.612 10.412l-2.892-20.824 65.944-17.932s-8.098-45.12 1.157-63.63 10.413-29.502 10.413-29.502z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#2f2e41"
          d="M951.958 233.686l-5.67-2.27s11.855-13.053 28.35-11.918L970 214.39s11.34-4.54 21.65 7.378c5.42 6.265 11.69 13.629 15.6 21.924h6.072l-2.534 5.58 8.87 5.581-9.105-1.002a31.184 31.184 0 01.247 9.256 11.976 11.976 0 01-4.385 7.835s-7.032-14.556-7.032-16.826v5.675s-5.67-5.108-5.67-8.513l-3.094 3.973H970l3.093-5.108-11.856 1.703 4.64-6.243-18.357 25.778s-10.51-29.183 4.439-37.695z"
          transform="translate(-32 -104.288)"
        ></path>
        <circle cx="565" cy="363.253" r="108" fill="#1abc9c"></circle>
        <path
          fill="#d0cde1"
          d="M650 489.007c0 6.627-24.333 23.25-52.5 23.25s-49.5-16.623-49.5-23.25 21.333 2.25 49.5 2.25 52.5-8.878 52.5-2.25z"
          transform="translate(-32 -104.288)"
        ></path>
        <circle cx="604" cy="332.253" r="12" fill="#3f3d56"></circle>
        <circle cx="526" cy="332.253" r="12" fill="#3f3d56"></circle>
        <circle cx="533" cy="326.253" r="6" fill="#d0cde1"></circle>
        <circle cx="594" cy="326.253" r="6" fill="#d0cde1"></circle>
        <circle cx="567" cy="219.253" r="43" fill="#1abc9c"></circle>
        <path
          fill="#3f3d56"
          d="M585.19 327.992c15.186 9.04 15.732 36.132 15.732 36.132s-24.076 12.435-39.262 3.394-15.731-36.132-15.731-36.132 24.076-12.434 39.262-3.394z"
          transform="translate(-32 -104.288)"
        ></path>
        <path
          fill="#3f3d56"
          d="M638.916 362.616c-13.724 11.135-39.34 2.3-39.34 2.3s-3.366-26.886 10.358-38.022 39.34-2.3 39.34-2.3 3.367 26.887-10.358 38.022z"
          transform="translate(-32 -104.288)"
        ></path>
      </svg>
    </SvgContainer>
  )
}

export default Respect
