import React from "react"
import styled from "styled-components"

const SvgContainer = styled.div`
  svg {
    width: 100%;
    height: 200px;
  }
`
function Love() {
  return (
    <SvgContainer>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1035.48"
        height="627.636"
        data-name="Layer 1"
        viewBox="0 0 1035.48 627.636"
      >
        <path
          fill="#f2f2f2"
          d="M769.26 350.83L418.423 136.182l-53.388 87.261a65.99 65.99 0 01-68.855 112.54l-17.325 28.318H426.74v179.073l360.31 220.444 75.41-123.257h-93.2z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <ellipse
          cx="514.424"
          cy="470.263"
          fill="#1abc9c"
          rx="63.537"
          ry="9.822"
        ></ellipse>
        <path
          fill="#1abc9c"
          d="M598.74 519.461l64.018-73.234a51 51 0 00-72.915-71.326 51 51 0 10-71.326 72.915l71.223 71.645"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M1 227.119H346.48V540.379H1z"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M689 190.119H1034.48V503.379H689z"
        ></path>
        <circle cx="249.48" cy="144.279" r="43" fill="#1abc9c"></circle>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M351.48 378.28s54-19 77 7 87-15 87-15M790.48 385.28s-10-24-78-2-164 21-188-13l64.018-73.235a51 51 0 00-72.915-71.326 51 51 0 10-71.326 72.915l71.223 71.645"
        ></path>
        <ellipse
          cx="522.536"
          cy="462.101"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          rx="63.537"
          ry="9.822"
        ></ellipse>
        <ellipse
          cx="515.776"
          cy="531.192"
          fill="#1abc9c"
          rx="50.019"
          ry="7.732"
        ></ellipse>
        <ellipse
          cx="515.776"
          cy="590.055"
          fill="#1abc9c"
          rx="33.796"
          ry="5.224"
        ></ellipse>
        <ellipse
          cx="522.536"
          cy="524.304"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          rx="50.019"
          ry="7.732"
        ></ellipse>
        <ellipse
          cx="522.536"
          cy="584.87"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="2"
          rx="33.796"
          ry="5.224"
        ></ellipse>
        <circle cx="202.598" cy="357.484" r="33.252" fill="#2f2e41"></circle>
        <path
          fill="#9f616a"
          d="M268.985 510.465s-10.83 23.982-13.925 24.756 25.53 14.699 25.53 14.699 5.415-29.398 7.736-30.945-19.341-8.51-19.341-8.51z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <circle cx="202.971" cy="369.641" r="22.435" fill="#9f616a"></circle>
        <path
          fill="#2f2e41"
          d="M304.02 676.56h-95.4c3.19-11.59 6.6-23.08 8.52-29.46.97-3.2 1.56-5.12 1.56-5.12s71.95 15.47 73.49 16.25a18.226 18.226 0 012.29 3.02c2.25 3.3 5.84 8.971 9.54 15.31z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#9f616a"
          d="M185.433 457.858l-23.21-35.587s5.74-37.331-1.996-30.369-10.185 35.08-10.185 35.08l26.88 40.933zM395.86 522.843l20.889-1.547s24.756-26.304 26.303-17.794-20.114 27.077-20.114 27.077l-28.625 3.095z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#3f3d56"
          d="M243.455 530.58v-7.737s-20.114-13.152-19.34-15.473-1.548-3.868-1.548-3.868-2.32-3.094-2.32-3.868-3.095-1.547-3.095-1.547L197.81 475.65s-10.83-21.661-13.152-20.888-14.699 5.416-13.151 8.51 44.87 69.627 44.87 69.627 2.321 14.7 6.19 14.7 20.887-17.02 20.887-17.02zM295.289 539.863s17.02-.774 17.793 0 11.604 0 11.604 0 29.398-6.963 31.72-9.284 41.002-12.378 42.55-7.736 5.415 13.925 1.546 15.473-108.308 27.85-108.308 27.85z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <circle cx="190.966" cy="322.486" r="13.76" fill="#2f2e41"></circle>
        <path
          fill="#2f2e41"
          d="M254.88 451.215a13.761 13.761 0 0112.327-13.685 13.915 13.915 0 00-1.434-.075 13.76 13.76 0 100 27.52 13.912 13.912 0 001.434-.075 13.761 13.761 0 01-12.327-13.685z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <ellipse
          cx="206.467"
          cy="350.433"
          fill="#2f2e41"
          rx="22.933"
          ry="13.76"
        ></ellipse>
        <path
          fill="#3f3d56"
          d="M262.41 522.456s-6.963 5.416-8.51 6.19-10.832-6.964-12.379-6.964-21.662 23.21-21.662 23.21 3.739 4.305 0 26.303c-1.401 8.25-4.183 43.52-4.183 48.162s-15.157 16.05-1.232 26.106 80.458 20.888 81.231 14.7-7.736-22.436-3.868-30.172 3.868-36.36 3.868-36.36l4.642-30.172 3.868-23.983s-18.18-5.029-19.727-5.029-8.51 10.831-22.049-11.99z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#1abc9c"
          d="M944.106 448.866H959.949V469.235H944.106z"
          transform="rotate(-2.221 -2602.242 2513.057)"
        ></path>
        <path
          fill="#ffb9b9"
          d="M888.135 519.85s-21.923-10.474-21.572-1.428 22.099 14.997 22.099 14.997z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#2f2e41"
          d="M990.08 639.56H886.09c2.17-10.18 6.3-22.46 9.53-31.31 2.56-7 4.54-11.86 4.54-11.86 11.81-13.32 28.95-13.66 45.03-10.37 1.92.39 3.83.83 5.71 1.32.22.05.44.11.66.17 3.76.97 7.41 2.09 10.86 3.21 5.95 1.94 11.32 3.9 15.66 5.23a38.586 38.586 0 006.7 1.57 11.374 11.374 0 001.5.06c1.72-.07 2.91 1.78 3.68 5.01 1.74 7.291 1.34 21.65.12 36.97z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#ffb9b9"
          d="M969.567 429.492s12.053 18.785 16.751 23.133-8.52 13.92-8.52 13.92l-23.658 3.182s.297-21.528-.921-23.746 16.348-16.489 16.348-16.489z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#1abc9c"
          d="M949.003 454.072s4.874 8.87 9.397 8.695 27.918-10.142 28.917-13.579 7.75 24.614 7.75 24.614L937.82 574.547l-13.964-9.651 6.766-59.15 5.557-31.925z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#575a89"
          d="M989.429 544.234a31.68 31.68 0 00-3.512 14.858c.009.226.018.452.038.678.541 10.466 4.397 22.57 5.78 31.372 1.004 6.314.718 10.923-2.977 12.006-8.959 2.612 1.306 4.48-33.353 16.016s-40.708 1.578-40.795-.683 4.908-19.443 3.733-20.53-16.435 14.227-21.046 12.141-.526-13.57-.526-13.57 10.387-24.184 10.168-29.838 15.733-32.319 15.733-32.319l10.851-70.633s4.172-9.222 9.782-10.572a13.897 13.897 0 0110.265 1.867l-9.388 20.748-9.904 36.623 1.657 13.526s11.517-24.229 19.082-33.582 23.693-31.495 23.693-31.495 3.765-12.705.923-16.502c-2.843-3.808 11.857-4.378 18.125 11.233 3.321 8.274 8.715 20.613 12.093 32.101 3.011 10.177 4.433 19.692 1.45 25.13-4.558 8.308-16.026 19.228-21.872 31.425z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          d="M1011.301 512.809c-4.558 8.308-16.026 19.228-21.872 31.425-1.527-5.501-3.39-11.193-3.39-11.193s-7.145-38.227 13.99-48.107a14.144 14.144 0 019.822 2.745c3.01 10.177 4.433 19.692 1.45 25.13z"
          opacity="0.1"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#575a89"
          d="M927.405 510.4l-17.916 5.225-24.877.964-2.56 21.616 40.883 2.945s10.212-28.708 4.47-30.75z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#ffb9b9"
          d="M956.621 583.08s-23.969 3.977-18.498 11.19 26.704-.37 26.704-.37z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          d="M985.955 559.77c.541 10.466 4.397 22.57 5.78 31.372-7.636 7.226-14.897 13.6-17.633 13.707-5.654.22-14.744-.56-18.093.701s-5.312-20.178-5.312-20.178 1.999-6.872 10.913-10.615c6.017-2.533 17.551-9.4 24.345-14.987z"
          opacity="0.1"
          transform="translate(-82.26 -136.182)"
        ></path>
        <path
          fill="#575a89"
          d="M999.765 478.15s19.135-3.007 18.102 28.742 2.017 52.015-5.68 57.976-32.695 32.977-38.348 33.196-14.744-.56-18.093.702-5.312-20.179-5.312-20.179 1.998-6.872 10.913-10.615 29.917-17.015 29.741-21.538-5.312-20.178-5.312-20.178-7.145-38.227 13.99-48.106z"
          transform="translate(-82.26 -136.182)"
        ></path>
        <circle cx="870.433" cy="296.23" r="24.896" fill="#ffb9b9"></circle>
        <path
          fill="#2f2e41"
          d="M935.93 396.729a4.807 4.807 0 01-2.093 1.05 1.494 1.494 0 01-1.731-1.199 6.207 6.207 0 01-2.375 3.047c-1.163.606-2.962.098-3.13-1.203a5.489 5.489 0 01-1.263 2.667 1.892 1.892 0 01-2.66.089c.51 2.783.196 5.645.355 8.47s.914 5.82 3.048 7.677c3.112 2.709 7.793 2.04 11.887 1.527a3.608 3.608 0 011.863.102c1.533.653 1.464 2.787 1.484 4.453a12.374 12.374 0 0010.836 11.751 6.203 6.203 0 004.61-1.125c1.162-.985 1.756-2.591 3.088-3.331 2.167-1.204 4.795.552 6.379 2.458s3.145 4.252 5.6 4.6c3.245.462 5.718-2.845 6.93-5.891a34.348 34.348 0 00-.682-26.689c-6.777-14.773-29.929-17.11-42.146-8.453z"
          transform="translate(-82.26 -136.182)"
        ></path>
      </svg>
    </SvgContainer>
  )
}

export default Love
